var pre = '/paper/';
export default {
  path: '/paper',
  title: '论文',
  icon: 'md-speedometer',
  children: [{
    path: "".concat(pre, "theme"),
    title: '主题'
  }, {
    path: "".concat(pre, "paper-list"),
    title: '论文'
  }]
};