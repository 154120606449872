var pre = '/member/';
export default {
  path: '/member',
  title: '会员',
  icon: 'md-speedometer',
  children: [{
    path: "".concat(pre, "set-meal"),
    title: '套餐'
  }, {
    path: "".concat(pre, "member-list"),
    title: '会员'
  }, {
    path: "".concat(pre, "order"),
    title: '订单'
  }, {
    path: "".concat(pre, "invoice"),
    title: '开票'
  }]
};