import iMenuSideTitle from "./menu-title";
import clickItem from "../mixins/click-item";
import menuBadge from "../mixins/menu-badge";
export default {
  name: 'iMenuSideItem',
  components: {
    iMenuSideTitle: iMenuSideTitle
  },
  mixins: [clickItem, menuBadge],
  props: {
    menu: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    hideTitle: {
      type: Boolean,
      default: false
    }
  }
};