import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.replace";
var formatDate = function formatDate(datetime, fmt) {
  var date = new Date(datetime);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  var o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (var k in o) {
    if (new RegExp("(".concat(k, ")")).test(fmt)) {
      var str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
    }
  }
  return fmt;
};
var padLeftZero = function padLeftZero(str) {
  return ('00' + str).substr(str.length);
};
export default {
  formatDate: formatDate
};