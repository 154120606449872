var pre = '/dynamic/';
export default {
  path: '/dynamic',
  title: '动态',
  icon: 'md-speedometer',
  children: [{
    path: "".concat(pre, "classify"),
    title: '分类'
  }, {
    path: "".concat(pre, "dynamic-list"),
    title: '动态'
  }]
};