import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/root/cpcic-admin-release/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import iMenuSideTitle from "./menu-title";
import clickItem from "../mixins/click-item";
import { mapState } from 'vuex';
export default {
  name: 'iMenuSideCollapse',
  components: {
    iMenuSideTitle: iMenuSideTitle
  },
  mixins: [clickItem],
  props: {
    menu: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 是否是第一级，区分在于左侧和展开侧
    topLevel: {
      type: Boolean,
      default: false
    }
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('admin/layout', ['siderTheme', 'showCollapseMenuTitle'])), mapState('admin/menu', ['activePath', 'openNames'])), {}, {
    dropdownClasses: function dropdownClasses() {
      return {
        'i-layout-menu-side-collapse-top': this.topLevel,
        'i-layout-menu-side-collapse-dark': this.siderTheme === 'dark'
      };
    },
    menuItemClasses: function menuItemClasses() {
      return ['ivu-menu-item i-layout-menu-side-collapse-top-item', {
        'ivu-menu-item-selected ivu-menu-item-active': this.openNames.indexOf(this.menu.path) >= 0 // -active 在高亮时，有背景
      }];
    }
  })
};