import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/root/cpcic-admin-release/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'operate-';
export default {
  path: '/operate',
  name: 'operate',
  redirect: {
    name: "".concat(pre, "comment")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'comment',
    name: "".concat(pre, "comment"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '评论',
      closable: false
    }),
    component: function component() {
      return import('@/pages/operate/comment');
    }
  }, {
    path: 'announce',
    name: "".concat(pre, "announce"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '公告',
      closable: false
    }),
    component: function component() {
      return import('@/pages/operate/announce');
    }
  }, {
    path: 'recommend',
    name: "".concat(pre, "recommend"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '推荐',
      closable: false
    }),
    component: function component() {
      return import('@/pages/operate/recommend');
    }
  }, {
    path: 'lottery',
    name: "".concat(pre, "lottery"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '抽奖',
      closable: false
    }),
    component: function component() {
      return import('@/pages/operate/lottery');
    }
  }, {
    path: 'lottery-details',
    name: "".concat(pre, "lottery-details"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '抽奖详情',
      closable: false
    }),
    component: function component() {
      return import('@/pages/operate/lottery/details');
    }
  }, {
    path: 'privacy',
    name: "".concat(pre, "privacy"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '隐私协议',
      closable: false
    }),
    component: function component() {
      return import('@/pages/operate/privacy');
    }
  }, {
    path: 'useragree',
    name: "".concat(pre, "useragree"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '用户协议',
      closable: false
    }),
    component: function component() {
      return import('@/pages/operate/useragree');
    }
  }]
};