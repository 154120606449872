import request from '@/plugins/request';

// 登录
export function AccountLogin(data) {
  return request({
    url: '/server/sso/auth/login',
    method: 'post',
    data: data
  });
}
// 会员列表
export function userInfoPage(data) {
  return request({
    url: '/server/data/admin/user/info/page',
    method: 'get',
    params: data
  });
}
// 会员保存
export function userInfoSave(data, key) {
  return request({
    url: '/server/data/admin/user/info/save',
    method: 'post',
    data: data,
    key: key
  });
}
// 会员详情
export function userInfoDetail(data) {
  return request({
    url: '/server/data/admin/user/info/detail/' + data,
    method: 'get'
  });
}
// 会员删除
export function userInfoDel(data) {
  return request({
    url: '/server/data/admin/user/info/remove',
    method: 'post',
    params: data
  });
}
// 会员状态
export function userInfoActive(data) {
  return request({
    url: '/server/data/admin/user/info/active',
    method: 'post',
    data: data
  });
}
// 会员白名单
export function userInfoWhitelist(data) {
  return request({
    url: '/server/data/admin/user/info/whitelist',
    method: 'post',
    data: data
  });
}
// 会员升级
export function userInfoUpgrade(data) {
  return request({
    url: '/server/data/admin/user/info/member/upgrade',
    method: 'post',
    data: data
  });
}
// 会员套餐列表
export function memberLevelList(data) {
  return request({
    url: '/server/data/admin/basic/member/level/list',
    method: 'get',
    params: data
  });
}
// 会员套餐保存
export function memberLevelSave(data, key) {
  return request({
    url: '/server/data/admin/basic/member/level/save',
    method: 'post',
    data: data,
    key: key
  });
}
// 订单列表
export function userOrderPage(data) {
  return request({
    url: '/server/data/admin/user/order/page',
    method: 'get',
    params: data
  });
}
// 开票列表
export function userInvoicePage(data) {
  return request({
    url: '/server/data/admin/user/invoice/page',
    method: 'get',
    params: data
  });
}
// 开票状态
export function userInvoiceExchange(data) {
  return request({
    url: '/server/data/admin/user/invoice/exchange',
    method: 'post',
    data: data
  });
}
// 人才列表
export function basicTalentList(data) {
  return request({
    url: '/server/data/admin/basic/talent/list',
    method: 'get',
    params: data
  });
}
// 人才分页
export function basicTalentPage(data) {
  return request({
    url: '/server/data/admin/basic/talent/page',
    method: 'get',
    params: data
  });
}
// 人才保存
export function basicTalentSave(data, key) {
  return request({
    url: '/server/data/admin/basic/talent/save',
    method: 'post',
    data: data,
    key: key
  });
}
// 人才详情
export function basicTalentDetail(data) {
  return request({
    url: '/server/data/admin/basic/talent/detail/' + data,
    method: 'get'
  });
}
// 人才大咖设定
export function basicTalentMaster(data) {
  return request({
    url: '/server/data/admin/basic/talent/master',
    method: 'post',
    data: data
  });
}
// 人才专家设定
export function basicTalentExpert(data) {
  return request({
    url: '/server/data/admin/basic/talent/expert',
    method: 'post',
    data: data
  });
}
// 企业列表
export function basicCorpList(data) {
  return request({
    url: '/server/data/admin/basic/corp/list',
    method: 'get',
    params: data
  });
}
// 企业分页
export function basicCorpPage(data) {
  return request({
    url: '/server/data/admin/basic/corp/page',
    method: 'get',
    params: data
  });
}
// 企业保存
export function basicCorpSave(data, key) {
  return request({
    url: '/server/data/admin/basic/corp/save',
    method: 'post',
    data: data,
    key: key
  });
}
// 企业详情
export function basicCorpDetail(data) {
  return request({
    url: '/server/data/admin/basic/corp/detail/' + data,
    method: 'get'
  });
}
// 场馆列表
export function basicVenueList(data) {
  return request({
    url: '/server/data/admin/basic/venue/list',
    method: 'get',
    params: data
  });
}
// 简历列表
export function basicTalentResumeList(data) {
  return request({
    url: '/server/data/admin/basic/talent/resume/list',
    method: 'get',
    params: data
  });
}
// 简历保存
export function basicTalentResumeSave(data) {
  return request({
    url: '/server/data/admin/basic/talent/resume/save',
    method: 'post',
    data: data
  });
}
// 大会详情
export function basicTalentResumeDetail(data) {
  return request({
    url: '/server/data/admin/basic/talent/resume/detail/' + data,
    method: 'get'
  });
}
// 大会列表
export function conferenceList(data) {
  return request({
    url: '/server/data/admin/conference/list',
    method: 'get',
    params: data
  });
}
// 大会列表
export function conferencePage(data) {
  return request({
    url: '/server/data/admin/conference/page',
    method: 'get',
    params: data
  });
}
// 大会详情
export function conferenceDetail(data) {
  return request({
    url: '/server/data/admin/conference/detail/' + data,
    method: 'get'
  });
}
// 大会保存
export function conferenceSave(data, key) {
  return request({
    url: '/server/data/admin/conference/info/save',
    method: 'post',
    data: data,
    key: key
  });
}
// 大会保存
export function conferenceRegSave(data, key) {
  return request({
    url: '/server/data/admin/conference/reg/save',
    method: 'post',
    data: data,
    key: key
  });
}
// 大会删除
export function conferenceDel(data) {
  return request({
    url: '/server/data/admin/conference/remove',
    method: 'post',
    params: data
  });
}
// 大会开启投票
export function conferenceVote(data) {
  return request({
    url: '/server/data/admin/conference/allow/vote',
    method: 'post',
    data: data
  });
}
// 大会改变状态
export function conferenceStatus(data) {
  return request({
    url: '/server/data/admin/conference/status',
    method: 'post',
    data: data
  });
}
// 大会改变排序值
export function conferenceRank(data) {
  return request({
    url: '/server/data/admin/conference/rank',
    method: 'post',
    data: data
  });
}
// 大会推荐
export function conferenceHot(data) {
  return request({
    url: '/server/data/admin/conference/hot',
    method: 'post',
    data: data
  });
}
// 日程分组
export function conferenceAgendaGroup(data) {
  return request({
    url: '/server/data/admin/conference/agenda/top/group',
    method: 'get',
    params: data
  });
}
// 日程树
export function conferenceAgendaTree(data) {
  return request({
    url: '/server/data/admin/conference/agenda/tree',
    method: 'get',
    params: data
  });
}
// 日程详情
export function conferenceAgendaDetail(data) {
  return request({
    url: '/server/data/admin/conference/agenda/detail/' + data,
    method: 'get'
  });
}
// 日程保存
export function conferenceAgendaSave(data, key) {
  return request({
    url: '/server/data/admin/conference/agenda/save',
    method: 'post',
    data: data,
    key: key
  });
}
// 日程删除
export function conferenceAgendaDel(data) {
  return request({
    url: '/server/data/admin/conference/agenda/remove',
    method: 'post',
    data: data
  });
}
// 日程改变状态
export function conferenceAgendaStatus(data) {
  return request({
    url: '/server/data/admin/conference/agenda/status',
    method: 'post',
    data: data
  });
}
// 日程改变排序值
export function conferenceAgendaRank(data) {
  return request({
    url: '/server/data/admin/conference/agenda/rank',
    method: 'post',
    data: data
  });
}
// 大会会场布局列表
export function venueLayoutList(data) {
  return request({
    url: '/server/data/admin/cms/content/cfVenueLayout/list',
    method: 'get',
    params: data
  });
}
// 大会会场布局保存
export function venueLayoutSave(data, key) {
  return request({
    url: '/server/data/admin/cms/content/cfVenueLayout/save',
    method: 'post',
    data: data,
    key: key
  });
}
// 大会会场布局删除
export function venueLayoutDel(data) {
  return request({
    url: '/server/data/admin/cms/content/cfVenueLayout/remove',
    method: 'post',
    data: data
  });
}
// 大会会场布局改变排序值
export function venueLayoutRank(data) {
  return request({
    url: '/server/data/admin/cms/content/cfVenueLayout/rank',
    method: 'post',
    data: data
  });
}
// 会议人员列表
export function conferenceAttendeeList(data) {
  return request({
    url: '/server/data/admin/conference/attendee/list',
    method: 'get',
    params: data
  });
}
// 会议人员列表
export function conferenceAttendeePage(data) {
  return request({
    url: '/server/data/admin/conference/attendee/page',
    method: 'get',
    params: data
  });
}
// 会议人员详情
export function conferenceAttendeeDetail(data) {
  return request({
    url: '/server/data/admin/conference/attendee/detail/' + data,
    method: 'get'
  });
}
// 会议人员保存
export function conferenceAttendeeSave(data, key) {
  return request({
    url: '/server/data/admin/conference/attendee/save',
    method: 'post',
    data: data,
    key: key
  });
}
// 会议人员删除
export function conferenceAttendeeDel(data) {
  return request({
    url: '/server/data/admin/conference/attendee/remove',
    method: 'post',
    params: data
  });
}
// 会议人员改变状态
export function conferenceAttendeeStatus(data) {
  return request({
    url: '/server/data/admin/conference/attendee/status',
    method: 'post',
    data: data
  });
}
// 会议人员改变排序值
export function conferenceAttendeeRank(data) {
  return request({
    url: '/server/data/admin/conference/attendee/rank',
    method: 'post',
    data: data
  });
}
// 会议人员展示
export function conferenceAttendeeShow(data) {
  return request({
    url: '/server/data/admin/conference/attendee/show',
    method: 'post',
    data: data
  });
}
// 会议酒店列表
export function conferenceVenueList(data) {
  return request({
    url: '/server/data/admin/conference/venue/list',
    method: 'get',
    params: data
  });
}
// 视频列表
export function resourcePage(url, data) {
  return request({
    url: "/server/data/admin/resource/".concat(url, "/page"),
    method: 'get',
    params: data
  });
}
// 视频详情
export function resourceDetail(url, data) {
  return request({
    url: "/server/data/admin/resource/".concat(url, "/detail/") + data,
    method: 'get'
  });
}
// 视频保存
export function resourceSave(url, data, key) {
  return request({
    url: "/server/data/admin/resource/".concat(url, "/save"),
    method: 'post',
    data: data,
    key: key
  });
}
// 视频删除
export function resourceDel(url, data) {
  return request({
    url: "/server/data/admin/resource/".concat(url, "/remove"),
    method: 'post',
    data: data
  });
}
// 视频改变状态
export function resourceStatus(url, data) {
  return request({
    url: "/server/data/admin/resource/".concat(url, "/status"),
    method: 'post',
    data: data
  });
}
// 视频改变排序值
export function resourceRank(url, data) {
  return request({
    url: "/server/data/admin/resource/".concat(url, "/rank"),
    method: 'post',
    data: data
  });
}
// 动态文章改变推荐
export function resourceHot(url, data) {
  return request({
    url: "/server/data/admin/resource/".concat(url, "/hot"),
    method: 'post',
    data: data
  });
}
// 视频密码保护
export function resourceVideoPrivacy(data) {
  return request({
    url: '/server/data/admin/resource/video/privacy',
    method: 'post',
    data: data
  });
}
// 酒店列表
export function conferenceVenuePage(data) {
  return request({
    url: '/server/data/admin/conference/venue/page',
    method: 'get',
    params: data
  });
}
// 酒店详情
export function conferenceVenueDetail(data) {
  return request({
    url: '/server/data/admin/conference/venue/detail/' + data,
    method: 'get'
  });
}
// 酒店保存
export function conferenceVenueSave(data, key) {
  return request({
    url: '/server/data/admin/conference/venue/save',
    method: 'post',
    data: data,
    key: key
  });
}
// 酒店删除
export function conferenceVenueDel(data) {
  return request({
    url: '/server/data/admin/conference/venue/remove',
    method: 'post',
    data: data
  });
}
// 酒店改变状态
export function conferenceVenueStatus(data) {
  return request({
    url: '/server/data/admin/conference/venue/status',
    method: 'post',
    data: data
  });
}
// 酒店改变排序值
export function conferenceVenueRank(data) {
  return request({
    url: '/server/data/admin/conference/venue/rank',
    method: 'post',
    data: data
  });
}
// 单位列表
export function conferenceCorpPage(data) {
  return request({
    url: '/server/data/admin/conference/corp/page',
    method: 'get',
    params: data
  });
}
// 单位列表
export function conferenceCorpList(data) {
  return request({
    url: '/server/data/admin/conference/corp/list',
    method: 'get',
    params: data
  });
}
// 单位详情
export function conferenceCorpDetail(data) {
  return request({
    url: '/server/data/admin/conference/corp/detail/' + data,
    method: 'get'
  });
}
// 单位保存
export function conferenceCorpSave(data, key) {
  return request({
    url: '/server/data/admin/conference/corp/save',
    method: 'post',
    data: data,
    key: key
  });
}
// 单位删除
export function conferenceCorpDel(data) {
  return request({
    url: '/server/data/admin/conference/corp/remove',
    method: 'post',
    data: data
  });
}
// 单位改变状态
export function conferenceCorpStatus(data) {
  return request({
    url: '/server/data/admin/conference/corp/status',
    method: 'post',
    data: data
  });
}
// 单位改变排序值
export function conferenceCorpRank(data) {
  return request({
    url: '/server/data/admin/conference/corp/rank',
    method: 'post',
    data: data
  });
}
// 大会报名
export function conferenceApplyPage(data) {
  return request({
    url: '/server/data/admin/conference/apply/page',
    method: 'get',
    params: data
  });
}
// 大会报名审核
export function conferenceApplyConfirm(data) {
  return request({
    url: '/server/data/admin/conference/apply/confirm',
    method: 'post',
    params: data
  });
}
// 大会报名取消
export function conferenceApplyCancel(data) {
  return request({
    url: '/server/data/admin/conference/apply/cancel',
    method: 'post',
    params: data
  });
}
// 评论列表
export function commentsPage(data) {
  return request({
    url: '/server/data/admin/comments/page',
    method: 'get',
    params: data
  });
}
// 评论审核
export function commentsOpen(data) {
  return request({
    url: '/server/data/admin/comments/open',
    method: 'post',
    data: data
  });
}
// 评论删除
export function commentsDel(data) {
  return request({
    url: '/server/data/admin/comments/remove',
    method: 'post',
    data: data
  });
}

// 动态
// 动态分类列表
export function categoryList(data) {
  return request({
    url: '/server/data/admin/article/category/list',
    method: 'get',
    params: data
  });
}
// 动态分类保存
export function categorySave(data) {
  return request({
    url: '/server/data/admin/article/category/save',
    method: 'post',
    data: data
  });
}
// 动态分类删除
export function categoryDel(data) {
  return request({
    url: '/server/data/admin/article/category/remove',
    method: 'post',
    data: data
  });
}
// 动态分类改变状态
export function categoryStatus(data) {
  return request({
    url: '/server/data/admin/article/category/status',
    method: 'post',
    data: data
  });
}
// 动态分类改变排序值
export function categoryRank(data) {
  return request({
    url: '/server/data/admin/article/category/rank',
    method: 'post',
    data: data
  });
}
// 动态文章列表
export function articlePage(data) {
  return request({
    url: '/server/data/admin/article/page',
    method: 'get',
    params: data
  });
}
// 动态文章详情
export function articleDetail(data) {
  return request({
    url: '/server/data/admin/article/detail/' + data,
    method: 'get'
  });
}
// 动态文章保存
export function articleSave(data, key) {
  return request({
    url: '/server/data/admin/article/save',
    method: 'post',
    data: data,
    key: key
  });
}
// 动态文章删除
export function articleDel(data) {
  return request({
    url: '/server/data/admin/article/remove',
    method: 'post',
    data: data
  });
}
// 动态文章改变状态
export function articleStatus(data) {
  return request({
    url: '/server/data/admin/article/status',
    method: 'post',
    data: data
  });
}
// 动态文章改变排序值
export function articleRank(data) {
  return request({
    url: '/server/data/admin/article/rank',
    method: 'post',
    data: data
  });
}
// 动态文章改变推荐
export function articleHot(data) {
  return request({
    url: '/server/data/admin/article/hot',
    method: 'post',
    data: data
  });
}

// 培训
// 培训列表
export function activityPage(data) {
  return request({
    url: '/server/data/admin/activity/page',
    method: 'get',
    params: data
  });
}
export function activityList(data) {
  return request({
    url: '/server/data/admin/activity/list',
    method: 'get',
    params: data
  });
}
// 培训详情
export function activityDetail(data) {
  return request({
    url: '/server/data/admin/activity/detail/' + data,
    method: 'get'
  });
}
// 培训保存
export function activitySave(data, key) {
  return request({
    url: '/server/data/admin/activity/info/save',
    method: 'post',
    data: data,
    key: key
  });
}
// 培训删除
export function activityDel(data) {
  return request({
    url: '/server/data/admin/activity/remove',
    method: 'post',
    data: data
  });
}
// 培训改变状态
export function activityStatus(data) {
  return request({
    url: '/server/data/admin/activity/status',
    method: 'post',
    data: data
  });
}
// 培训改变排序值
export function activityRank(data) {
  return request({
    url: '/server/data/admin/activity/rank',
    method: 'post',
    data: data
  });
}
// 培训改变推荐
export function activityHot(data) {
  return request({
    url: '/server/data/admin/activity/hot',
    method: 'post',
    data: data
  });
}
// 培训人员列表
export function activityLecturerPage(data) {
  return request({
    url: '/server/data/admin/activity/lecturer/page',
    method: 'get',
    params: data
  });
}
// 培训人员详情
export function activityLecturerDetail(data) {
  return request({
    url: '/server/data/admin/activity/lecturer/detail/' + data,
    method: 'get'
  });
}
// 培训人员保存
export function activityLecturerSave(data, key) {
  return request({
    url: '/server/data/admin/activity/lecturer/save',
    method: 'post',
    data: data,
    key: key
  });
}
// 培训人员删除
export function activityLecturerDel(data) {
  return request({
    url: '/server/data/admin/activity/lecturer/remove',
    method: 'post',
    data: data
  });
}
// 培训人员改变状态
export function activityLecturerStatus(data) {
  return request({
    url: '/server/data/admin/activity/lecturer/status',
    method: 'post',
    data: data
  });
}
// 培训人员改变排序值
export function activityLecturerRank(data) {
  return request({
    url: '/server/data/admin/activity/lecturer/rank',
    method: 'post',
    data: data
  });
}
// 培训合作企业列表
export function activityCorpPage(data) {
  return request({
    url: '/server/data/admin/activity/corp/page',
    method: 'get',
    params: data
  });
}
// 培训合作企业详情
export function activityCorpDetail(data) {
  return request({
    url: '/server/data/admin/activity/corp/detail/' + data,
    method: 'get'
  });
}
// 培训合作企业保存
export function activityCorpSave(data, key) {
  return request({
    url: '/server/data/admin/activity/corp/save',
    method: 'post',
    data: data,
    key: key
  });
}
// 培训合作企业删除
export function activityCorpDel(data) {
  return request({
    url: '/server/data/admin/activity/corp/remove',
    method: 'post',
    data: data
  });
}
// 培训合作企业改变状态
export function activityCorpStatus(data) {
  return request({
    url: '/server/data/admin/activity/corp/status',
    method: 'post',
    data: data
  });
}
// 培训合作企业改变排序值
export function activityCorpRank(data) {
  return request({
    url: '/server/data/admin/activity/corp/rank',
    method: 'post',
    data: data
  });
}
// 公告列表
export function announcePage(data) {
  return request({
    url: '/server/data/admin/cms/content/announce/page',
    method: 'get',
    params: data
  });
}
// 公告详情
export function announceDetail(data) {
  return request({
    url: '/server/data/admin/cms/content/announce/detail/' + data,
    method: 'get'
  });
}
// 公告保存
export function announceSave(data, key) {
  return request({
    url: '/server/data/admin/cms/content/announce/save',
    method: 'post',
    data: data,
    key: key
  });
}
// 公告删除
export function announceDel(data) {
  return request({
    url: '/server/data/admin/cms/content/announce/remove',
    method: 'post',
    data: data
  });
}
// 公告改变状态
export function announceStatus(data) {
  return request({
    url: '/server/data/admin/cms/content/announce/status',
    method: 'post',
    data: data
  });
}
// 公告改变排序值
export function announceRank(data) {
  return request({
    url: '/server/data/admin/cms/content/announce/rank',
    method: 'post',
    data: data
  });
}
// 公告推荐
export function announceHot(data) {
  return request({
    url: '/server/data/admin/cms/content/announce/hot',
    method: 'post',
    data: data
  });
}
// 公告推送
export function basicMsgSend(data, key) {
  return request({
    url: '/server/data/admin/basic/msg/send',
    method: 'post',
    data: data,
    key: key
  });
}
// 业务选择
export function recommendChoose(data) {
  return request({
    url: '/server/data/admin/basic/recommend/choose/business',
    method: 'get',
    params: data
  });
}
// 推荐列表
export function recommendPage(data) {
  return request({
    url: '/server/data/admin/basic/recommend/page',
    method: 'get',
    params: data
  });
}
// 推荐详情
export function recommendDetail(data) {
  return request({
    url: '/server/data/admin/basic/recommend/detail/' + data,
    method: 'get'
  });
}
// 推荐保存
export function recommendSave(data, key) {
  return request({
    url: '/server/data/admin/basic/recommend/save',
    method: 'post',
    data: data,
    key: key
  });
}
// 推荐删除
export function recommendDel(data) {
  return request({
    url: '/server/data/admin/basic/recommend/remove',
    method: 'post',
    data: data
  });
}
// 推荐改变状态
export function recommendStatus(data) {
  return request({
    url: '/server/data/admin/basic/recommend/status',
    method: 'post',
    data: data
  });
}
// 推荐改变排序值
export function recommendRank(data) {
  return request({
    url: '/server/data/admin/basic/recommend/rank',
    method: 'post',
    data: data
  });
}
// 论文目录列表
export function papersTree(data) {
  return request({
    url: '/server/data/admin/resource/category/papers/tree',
    method: 'get',
    params: data
  });
}
// 论文目录保存
export function papersSave(data) {
  return request({
    url: '/server/data/admin/resource/category/papers/save',
    method: 'post',
    data: data
  });
}
// 论文目录删除
export function papersDel(data) {
  return request({
    url: '/server/data/admin/resource/category/papers/remove',
    method: 'post',
    data: data
  });
}
// 论文目录排序值
export function papersRank(data) {
  return request({
    url: '/server/data/admin/resource/category/papers/rank',
    method: 'post',
    data: data
  });
}
// 资源访问
export function resourceVisitPage(data) {
  return request({
    url: '/server/data/admin/resource/visit/page',
    method: 'get',
    params: data
  });
}
// 业务对象清单
export function basicBizList(data) {
  return request({
    url: '/server/data/admin/basic/biz/list',
    method: 'post',
    data: data
  });
}
// 业务对象清单
export function basicBizChooseList(data) {
  return request({
    url: '/server/data/admin/basic/biz/choose/visit',
    method: 'post',
    data: data
  });
}
// 抽奖列表
export function lotteryActivityPage(data) {
  return request({
    url: '/server/data/admin/lottery/activity/page',
    method: 'get',
    params: data
  });
}
// 抽奖详情
export function lotteryActivityDetail(data) {
  return request({
    url: '/server/data/admin/lottery/activity/detail/' + data,
    method: 'get'
  });
}
// 抽奖保存
export function lotteryActivitySave(data) {
  return request({
    url: '/server/data/admin/lottery/activity/save',
    method: 'post',
    data: data
  });
}
// 抽奖删除
export function lotteryActivityDel(data) {
  return request({
    url: '/server/data/admin/lottery/activity/remove',
    method: 'post',
    data: data
  });
}
// 抽奖改变状态
export function lotteryActivityStatus(data) {
  return request({
    url: '/server/data/admin/lottery/activity/status',
    method: 'post',
    data: data
  });
}
// 抽奖列表
export function lotteryAwardList(data) {
  return request({
    url: '/server/data/admin/lottery/award/list',
    method: 'get',
    params: data
  });
}
// 抽奖删除
export function lotteryAwardDel(data) {
  return request({
    url: '/server/data/admin/lottery/award/remove',
    method: 'post',
    data: data
  });
}
// 抽奖奖励保存
export function lotteryAwardSave(data, key) {
  return request({
    url: '/server/data/admin/lottery/award/save',
    method: 'post',
    data: data,
    key: key
  });
}
// 推荐改变排序值
export function lotteryAwardRank(data) {
  return request({
    url: '/server/data/admin/lottery/award/rank',
    method: 'post',
    data: data
  });
}
// 中奖名单
export function lotteryParticipantList(data) {
  return request({
    url: '/server/data/admin/lottery/participant/list',
    method: 'get',
    params: data
  });
}
// 大会投票
export function voteSearch(data) {
  return request({
    url: '/server/data/admin/vote/search',
    method: 'get',
    params: data
  });
}
// 隐私
export function privacyLatest(data) {
  return request({
    url: '/server/data/admin/cms/content/privacy/latest',
    method: 'get',
    params: data
  });
}
// 隐私保存
export function privacySave(data) {
  return request({
    url: '/server/data/admin/cms/content/privacy/save',
    method: 'post',
    data: data
  });
}
// 用户协议
export function useragreeLatest(data) {
  return request({
    url: '/server/data/admin/cms/content/useragree/latest',
    method: 'get',
    params: data
  });
}
// 用户协议保存
export function useragreeSave(data) {
  return request({
    url: '/server/data/admin/cms/content/useragree/save',
    method: 'post',
    data: data
  });
}