var pre = '/operate/';
export default {
  path: '/operate',
  title: '运营',
  icon: 'md-speedometer',
  children: [{
    path: "".concat(pre, "comment"),
    title: '评论'
  }, {
    path: "".concat(pre, "announce"),
    title: '公告'
  }, {
    path: "".concat(pre, "recommend"),
    title: '推荐'
  }, {
    path: "".concat(pre, "lottery"),
    title: '抽奖'
  }, {
    path: "".concat(pre, "privacy"),
    title: '隐私协议'
  }, {
    path: "".concat(pre, "useragree"),
    title: '用户协议'
  }]
};