import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/root/cpcic-admin-release/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'train-';
export default {
  path: '/train',
  name: 'train',
  redirect: {
    name: "".concat(pre, "train-list")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'train-list',
    name: "".concat(pre, "train-list"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '培训',
      closable: false
    }),
    component: function component() {
      return import('@/pages/train/train-list');
    }
  }, {
    path: 'place',
    name: "".concat(pre, "place"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '地点',
      closable: false
    }),
    component: function component() {
      return import('@/pages/train/place');
    }
  }, {
    path: 'lecturer',
    name: "".concat(pre, "lecturer"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '讲师',
      closable: false
    }),
    component: function component() {
      return import('@/pages/train/lecturer');
    }
  }, {
    path: 'company',
    name: "".concat(pre, "company"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '企业',
      closable: false
    }),
    component: function component() {
      return import('@/pages/train/company');
    }
  }, {
    path: 'resources',
    name: "".concat(pre, "resources"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '资源',
      closable: false
    }),
    component: function component() {
      return import('@/pages/train/resources');
    }
  }, {
    path: 'sign-up',
    name: "".concat(pre, "sign-up"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '报名',
      closable: false
    }),
    component: function component() {
      return import('@/pages/train/sign-up');
    }
  }]
};