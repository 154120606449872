var pre = '/train/';
export default {
  path: '/train',
  title: '培训',
  icon: 'md-speedometer',
  children: [{
    path: "".concat(pre, "train-list"),
    title: '培训'
  },
  // {
  //     path: `${pre}place`,
  //     title: '地点'
  // },
  {
    path: "".concat(pre, "lecturer"),
    title: '讲师'
  }, {
    path: "".concat(pre, "company"),
    title: '企业'
  }, {
    path: "".concat(pre, "resources"),
    title: '资源'
  }
  // {
  //     path: `${pre}sign-up`,
  //     title: '报名'
  // }
  ]
};