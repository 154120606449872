var pre = '/statistics/';
export default {
  path: '/statistics',
  title: '统计',
  icon: 'md-speedometer',
  children: [{
    path: "".concat(pre, "resource-visit"),
    title: '资源访问'
  }, {
    path: "".concat(pre, "vote"),
    title: '投票'
  }]
};