import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/root/cpcic-admin-release/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'currency-';
export default {
  path: '/currency',
  name: 'currency',
  redirect: {
    name: "".concat(pre, "country")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'country',
    name: "".concat(pre, "country"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '国家',
      closable: false
    }),
    component: function component() {
      return import('@/pages/currency/country');
    }
  }, {
    path: 'venue',
    name: "".concat(pre, "venue"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '场馆',
      closable: false
    }),
    component: function component() {
      return import('@/pages/currency/venue');
    }
  }, {
    path: 'company',
    name: "".concat(pre, "company"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '单位',
      closable: false
    }),
    component: function component() {
      return import('@/pages/currency/company');
    }
  }, {
    path: 'personnel',
    name: "".concat(pre, "personnel"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '人才',
      closable: false
    }),
    component: function component() {
      return import('@/pages/currency/personnel');
    }
  }]
};