import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/root/cpcic-admin-release/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { mapGetters } from 'vuex';
export default {
  name: 'iHeaderLog',
  computed: _objectSpread(_objectSpread({}, mapGetters('admin/log', ['length', 'lengthError'])), {}, {
    showDot: function showDot() {
      return !!this.length && this.lengthError === 0;
    },
    tooltipContent: function tooltipContent() {
      if (!this.length) {
        return '没有日志或异常';
      } else {
        var text = "".concat(this.length, " \u6761\u65E5\u5FD7");
        if (this.lengthError) text += " | \u5305\u542B ".concat(this.lengthError, " \u4E2A\u5F02\u5E38");
        return text;
      }
    }
  }),
  methods: {
    handleOpenLog: function handleOpenLog() {
      this.$router.push({
        name: 'log'
      }, function () {});
    }
  }
};