var pre = '/meeting/';
export default {
  path: '/meeting',
  title: '大会',
  icon: 'md-speedometer',
  children: [{
    path: "".concat(pre, "meeting-list"),
    title: '大会'
  }, {
    path: "".concat(pre, "hotel"),
    title: '酒店'
  }, {
    path: "".concat(pre, "company"),
    title: '单位'
  }, {
    path: "".concat(pre, "member"),
    title: '成员'
  }, {
    path: "".concat(pre, "schedule"),
    title: '日程'
  }, {
    path: "".concat(pre, "venue-layout"),
    title: '布局'
  }, {
    path: "".concat(pre, "resources"),
    title: '资源'
  }, {
    path: "".concat(pre, "sign-up"),
    title: '报名'
  }]
};