import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.split";
import cookies from "./util.cookies";
import log from "./util.log";
import db from "./util.db";
import Setting from '@/setting';
var util = {
  cookies: cookies,
  log: log,
  db: db
};
function tTitle() {
  var title = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  if (window && window.$t) {
    if (title.indexOf('$t:') === 0) {
      return window.$t(title.split('$t:')[1]);
    } else {
      return title;
    }
  } else {
    return title;
  }
}

/**
 * @description 更改标题
 * @param {Object} title 标题
 * @param {Object} count 未读消息数提示（可视情况选择使用或不使用）
 */
util.title = function (_ref) {
  var title = _ref.title,
    count = _ref.count;
  title = tTitle(title);
  var fullTitle = title ? "".concat(title, " - ").concat(Setting.titleSuffix) : Setting.titleSuffix;
  if (count) fullTitle = "(".concat(count, "\u6761\u6D88\u606F)").concat(fullTitle);
  window.document.title = fullTitle;
};
function requestAnimation(task) {
  if ('requestAnimationFrame' in window) {
    return window.requestAnimationFrame(task);
  }
  setTimeout(task, 16);
}
export { requestAnimation };
export default util;
/**
 * 递归调用
 */
var _jsonToArray = function jsonToArray(nodes, key) {
  var arr = nodes;
  arr.forEach(function (v) {
    if (key) {
      v.expand = true;
    }
    v.value = v.id;
    if (v.topic) {
      v.title = v.topic;
    } else {
      v.title = v.name;
    }
    if (v.subset && v.subset.length > 0) {
      _jsonToArray(v.subset);
    } else {
      v.subset = [];
    }
    if (v.subset && v.subset.length > 0) {
      v.children = v.subset;
      _jsonToArray(v.children);
    } else {
      v.children = [];
    }
  });
  return arr;
};
/**
 * 日期格式化
 */
export { _jsonToArray as jsonToArray };
export var formatDate = function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  var o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (var k in o) {
    if (new RegExp("(".concat(k, ")")).test(fmt)) {
      var str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
    }
  }
  return fmt;
};
export var padLeftZero = function padLeftZero(str) {
  return ('00' + str).substr(str.length);
};