var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "i-frame"
  }, [_vm.ready ? _c("iframe", {
    staticClass: "i-frame-iframe",
    attrs: {
      src: _vm.src,
      frameborder: "0"
    }
  }) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };