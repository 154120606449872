var pre = '/currency/';
export default {
  path: '/currency',
  title: '通用',
  icon: 'md-speedometer',
  children: [{
    path: "".concat(pre, "country"),
    title: '国家'
  }, {
    path: "".concat(pre, "venue"),
    title: '场馆'
  }, {
    path: "".concat(pre, "company"),
    title: '单位'
  }, {
    path: "".concat(pre, "personnel"),
    title: '人才'
  }]
};