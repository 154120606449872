var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "i-layout-header-trigger i-layout-header-trigger-min",
    on: {
      click: _vm.showSetting
    }
  }, [_c("Icon", {
    attrs: {
      type: "md-more"
    }
  }), _c("Drawer", {
    attrs: {
      width: "280"
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("Divider", {
    attrs: {
      size: "small"
    }
  }, [_vm._v("主题风格设置")]), _c("div", {
    staticClass: "i-layout-header-setting-item"
  }, [_c("div", {
    staticClass: "i-layout-header-setting-item-radio",
    class: {
      on: _vm.siderTheme === "dark"
    },
    on: {
      click: function click($event) {
        return _vm.handleChangeSetting("siderTheme", "dark");
      }
    }
  }, [_c("Tooltip", {
    attrs: {
      content: "暗色侧边栏",
      placement: "top",
      transfer: ""
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/svg/nav-theme-dark.svg")
    }
  })])], 1), _c("div", {
    staticClass: "i-layout-header-setting-item-radio",
    class: {
      on: _vm.siderTheme === "light"
    },
    on: {
      click: function click($event) {
        return _vm.handleChangeSetting("siderTheme", "light");
      }
    }
  }, [_c("Tooltip", {
    attrs: {
      content: "亮色侧边栏",
      placement: "top",
      transfer: ""
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/svg/nav-theme-light.svg")
    }
  })])], 1)]), _c("div", {
    staticClass: "i-layout-header-setting-item"
  }, [_c("div", {
    staticClass: "i-layout-header-setting-item-radio",
    class: {
      on: _vm.headerTheme === "light"
    },
    on: {
      click: function click($event) {
        return _vm.handleChangeSetting("headerTheme", "light");
      }
    }
  }, [_c("Tooltip", {
    attrs: {
      content: "亮色顶栏",
      placement: "top",
      transfer: ""
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/svg/nav-theme-dark.svg")
    }
  })])], 1), _c("div", {
    staticClass: "i-layout-header-setting-item-radio",
    class: {
      on: _vm.headerTheme === "dark"
    },
    on: {
      click: function click($event) {
        return _vm.handleChangeSetting("headerTheme", "dark");
      }
    }
  }, [_c("Tooltip", {
    attrs: {
      content: "暗色顶栏",
      placement: "top",
      transfer: ""
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/svg/header-theme-dark.svg")
    }
  })])], 1), _c("div", {
    staticClass: "i-layout-header-setting-item-radio",
    class: {
      on: _vm.headerTheme === "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handleChangeSetting("headerTheme", "primary");
      }
    }
  }, [_c("Tooltip", {
    attrs: {
      content: "主色顶栏",
      placement: "top",
      transfer: ""
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/svg/header-theme-primary.svg")
    }
  })])], 1)]), _c("Divider", {
    attrs: {
      size: "small"
    }
  }, [_vm._v("导航设置")]), _c("div", {
    staticClass: "i-layout-header-setting-item"
  }, [_c("span", {
    staticClass: "i-layout-header-setting-item-desc"
  }, [_vm._v("显示顶栏菜单")]), _c("span", {
    staticClass: "i-layout-header-setting-item-action"
  }, [_c("i-switch", {
    attrs: {
      size: "small",
      value: _vm.headerMenu
    },
    on: {
      "on-change": function onChange(val) {
        return _vm.handleChangeSetting("headerMenu", val);
      }
    }
  })], 1)]), _c("div", {
    staticClass: "i-layout-header-setting-item"
  }, [_c("span", {
    staticClass: "i-layout-header-setting-item-desc"
  }, [_vm._v("固定侧边栏")]), _c("span", {
    staticClass: "i-layout-header-setting-item-action"
  }, [_c("i-switch", {
    attrs: {
      size: "small",
      value: _vm.siderFix
    },
    on: {
      "on-change": function onChange(val) {
        return _vm.handleChangeSetting("siderFix", val);
      }
    }
  })], 1)]), _c("div", {
    staticClass: "i-layout-header-setting-item"
  }, [_c("span", {
    staticClass: "i-layout-header-setting-item-desc"
  }, [_vm._v("固定顶栏")]), _c("span", {
    staticClass: "i-layout-header-setting-item-action"
  }, [_c("i-switch", {
    attrs: {
      size: "small",
      value: _vm.headerFix
    },
    on: {
      "on-change": function onChange(val) {
        return _vm.handleChangeSetting("headerFix", val);
      }
    }
  })], 1)]), _c("div", {
    staticClass: "i-layout-header-setting-item",
    class: {
      "i-layout-header-setting-item-disabled": !_vm.headerFix
    }
  }, [_c("span", {
    staticClass: "i-layout-header-setting-item-desc"
  }, [_vm._v("\n                下滑时隐藏顶栏\n                "), _c("Tooltip", {
    attrs: {
      placement: "top",
      content: "需开启固定顶栏",
      transfer: ""
    }
  }, [_c("Icon", {
    attrs: {
      type: "ios-help-circle-outline"
    }
  })], 1)], 1), _c("span", {
    staticClass: "i-layout-header-setting-item-action"
  }, [_c("i-switch", {
    attrs: {
      size: "small",
      value: _vm.headerHide,
      disabled: !_vm.headerFix
    },
    on: {
      "on-change": function onChange(val) {
        return _vm.handleChangeSetting("headerHide", val);
      }
    }
  })], 1)]), _c("div", {
    staticClass: "i-layout-header-setting-item",
    class: {
      "i-layout-header-setting-item-disabled": !_vm.headerFix
    }
  }, [_c("span", {
    staticClass: "i-layout-header-setting-item-desc"
  }, [_vm._v("\n                置顶顶栏\n                "), _c("Tooltip", {
    attrs: {
      placement: "top",
      content: "需开启固定顶栏",
      transfer: ""
    }
  }, [_c("Icon", {
    attrs: {
      type: "ios-help-circle-outline"
    }
  })], 1)], 1), _c("span", {
    staticClass: "i-layout-header-setting-item-action"
  }, [_c("i-switch", {
    attrs: {
      size: "small",
      value: _vm.headerStick,
      disabled: !_vm.headerFix
    },
    on: {
      "on-change": function onChange(val) {
        return _vm.handleChangeSetting("headerStick", val);
      }
    }
  })], 1)]), _c("div", {
    staticClass: "i-layout-header-setting-item"
  }, [_c("span", {
    staticClass: "i-layout-header-setting-item-desc"
  }, [_vm._v("侧边栏开启手风琴模式")]), _c("span", {
    staticClass: "i-layout-header-setting-item-action"
  }, [_c("i-switch", {
    attrs: {
      size: "small",
      value: _vm.menuAccordion
    },
    on: {
      "on-change": function onChange(val) {
        return _vm.handleChangeSetting("menuAccordion", val);
      }
    }
  })], 1)]), _c("div", {
    staticClass: "i-layout-header-setting-item"
  }, [_c("span", {
    staticClass: "i-layout-header-setting-item-desc"
  }, [_vm._v("显示折叠侧边栏按钮")]), _c("span", {
    staticClass: "i-layout-header-setting-item-action"
  }, [_c("i-switch", {
    attrs: {
      size: "small",
      value: _vm.showSiderCollapse
    },
    on: {
      "on-change": function onChange(val) {
        return _vm.handleChangeSetting("showSiderCollapse", val);
      }
    }
  })], 1)]), _c("div", {
    staticClass: "i-layout-header-setting-item"
  }, [_c("span", {
    staticClass: "i-layout-header-setting-item-desc"
  }, [_vm._v("侧边栏折叠时显示父级菜单名")]), _c("span", {
    staticClass: "i-layout-header-setting-item-action"
  }, [_c("i-switch", {
    attrs: {
      size: "small",
      value: _vm.showCollapseMenuTitle
    },
    on: {
      "on-change": function onChange(val) {
        return _vm.handleChangeSetting("showCollapseMenuTitle", val);
      }
    }
  })], 1)]), _c("div", {
    staticClass: "i-layout-header-setting-item"
  }, [_c("span", {
    staticClass: "i-layout-header-setting-item-desc"
  }, [_vm._v("\n                显示全局面包屑导航\n                "), _c("Tooltip", {
    attrs: {
      placement: "top",
      content: "headerMenu 开启时无效",
      transfer: ""
    }
  }, [_c("Icon", {
    attrs: {
      type: "ios-help-circle-outline"
    }
  })], 1)], 1), _c("span", {
    staticClass: "i-layout-header-setting-item-action"
  }, [_c("i-switch", {
    attrs: {
      size: "small",
      value: _vm.showBreadcrumb
    },
    on: {
      "on-change": function onChange(val) {
        return _vm.handleChangeSetting("showBreadcrumb", val);
      }
    }
  })], 1)]), _c("div", {
    staticClass: "i-layout-header-setting-item",
    class: {
      "i-layout-header-setting-item-disabled": !_vm.showBreadcrumb
    }
  }, [_c("span", {
    staticClass: "i-layout-header-setting-item-desc"
  }, [_vm._v("\n                全局面包屑显示图标\n                "), _c("Tooltip", {
    attrs: {
      placement: "top",
      content: "需开启全局面包屑导航",
      transfer: ""
    }
  }, [_c("Icon", {
    attrs: {
      type: "ios-help-circle-outline"
    }
  })], 1)], 1), _c("span", {
    staticClass: "i-layout-header-setting-item-action"
  }, [_c("i-switch", {
    attrs: {
      size: "small",
      value: _vm.showBreadcrumbIcon,
      disabled: !_vm.showBreadcrumb
    },
    on: {
      "on-change": function onChange(val) {
        return _vm.handleChangeSetting("showBreadcrumbIcon", val);
      }
    }
  })], 1)]), _c("div", {
    staticClass: "i-layout-header-setting-item"
  }, [_c("span", {
    staticClass: "i-layout-header-setting-item-desc"
  }, [_vm._v("显示重载页面按钮")]), _c("span", {
    staticClass: "i-layout-header-setting-item-action"
  }, [_c("i-switch", {
    attrs: {
      size: "small",
      value: _vm.showReload
    },
    on: {
      "on-change": function onChange(val) {
        return _vm.handleChangeSetting("showReload", val);
      }
    }
  })], 1)]), _c("div", {
    staticClass: "i-layout-header-setting-item"
  }, [_c("span", {
    staticClass: "i-layout-header-setting-item-desc"
  }, [_vm._v("显示多语言选择")]), _c("span", {
    staticClass: "i-layout-header-setting-item-action"
  }, [_c("i-switch", {
    attrs: {
      size: "small",
      value: _vm.showI18n
    },
    on: {
      "on-change": function onChange(val) {
        return _vm.handleChangeSetting("showI18n", val);
      }
    }
  })], 1)]), _c("Divider", {
    attrs: {
      size: "small"
    }
  }, [_vm._v("其它设置")]), _c("div", {
    staticClass: "i-layout-header-setting-item"
  }, [_c("span", {
    staticClass: "i-layout-header-setting-item-desc"
  }, [_vm._v("开启多页签")]), _c("span", {
    staticClass: "i-layout-header-setting-item-action"
  }, [_c("i-switch", {
    attrs: {
      size: "small",
      value: _vm.tabs
    },
    on: {
      "on-change": function onChange(val) {
        return _vm.handleChangeSetting("tabs", val);
      }
    }
  })], 1)]), _c("div", {
    staticClass: "i-layout-header-setting-item",
    class: {
      "i-layout-header-setting-item-disabled": !_vm.tabs
    }
  }, [_c("span", {
    staticClass: "i-layout-header-setting-item-desc"
  }, [_vm._v("\n                多页签显示图标\n                "), _c("Tooltip", {
    attrs: {
      placement: "top",
      content: "需开启多页签",
      transfer: ""
    }
  }, [_c("Icon", {
    attrs: {
      type: "ios-help-circle-outline"
    }
  })], 1)], 1), _c("span", {
    staticClass: "i-layout-header-setting-item-action"
  }, [_c("i-switch", {
    attrs: {
      size: "small",
      value: _vm.showTabsIcon,
      disabled: !_vm.tabs
    },
    on: {
      "on-change": function onChange(val) {
        return _vm.handleChangeSetting("showTabsIcon", val);
      }
    }
  })], 1)]), _c("div", {
    staticClass: "i-layout-header-setting-item",
    class: {
      "i-layout-header-setting-item-disabled": !_vm.tabs
    }
  }, [_c("span", {
    staticClass: "i-layout-header-setting-item-desc"
  }, [_vm._v("\n                固定多页签\n                "), _c("Tooltip", {
    attrs: {
      placement: "top",
      content: "需开启多页签",
      transfer: ""
    }
  }, [_c("Icon", {
    attrs: {
      type: "ios-help-circle-outline"
    }
  })], 1)], 1), _c("span", {
    staticClass: "i-layout-header-setting-item-action"
  }, [_c("i-switch", {
    attrs: {
      size: "small",
      value: _vm.tabsFix,
      disabled: !_vm.tabs
    },
    on: {
      "on-change": function onChange(val) {
        return _vm.handleChangeSetting("tabsFix", val);
      }
    }
  })], 1)]), _c("Alert", {
    attrs: {
      type: "warning"
    }
  }, [_c("div", {
    attrs: {
      slot: "desc"
    },
    slot: "desc"
  }, [_vm._v("\n                该功能主要实时预览各种布局效果，更多完整配置在 "), _c("strong", [_vm._v("setting.js")]), _vm._v(" 中设置。建议在生产环境关闭该布局预览功能。\n            ")])])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };