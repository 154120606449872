// 菜单，侧边栏
import currency from "./modules/currency";
import member from "./modules/member";
import dynamic from "./modules/dynamic";
import meeting from "./modules/meeting";
import train from "./modules/train";
import paper from "./modules/paper";
import operate from "./modules/operate";
import statistics from "./modules/statistics";
export default [{
  path: '/home',
  title: '首页',
  icon: 'md-speedometer'
}, currency, member, dynamic, meeting, train, paper, operate, statistics];